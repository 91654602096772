import { ApiFile } from '../files';
import { SettingsApi } from '../settings';
import { SocialMediaApi } from '../social-media';

export interface CreatePageBody {
  guid: string;
  type: 'page';
  name: string;
  active: boolean;
  status: string;
}

export interface PrimaryDomain {
  domain: string; // "sparksites.io",
  subdomain: string; // "admit"
}

export interface PageShort {
  guid: string; // "spark.sites.1",
  type: 'page' | string;
  name: string; // "This is a Page",
  status: string; // "published",
  active: boolean; // true,
  has_unpublished_updates: boolean; // false,
  last_publication_at: string; // "2017-04-26T13:39:11.394Z",
  primary_domain: PrimaryDomain;
  preview_key: string; // "7D1A572022DD079A08D63D98C69D9012",
  preview_image: ApiFile<PageThumbnailTransformations>; // "https://s3.amazonaws.com/spark/public/files/w9wnODgel9mGYcUwP2sK/preview.png",
  created_at: string; // "2017-04-26T13:39:11.394Z",
  updated_at: string; // "2017-04-26T13:39:11.394Z",
  subpages?: Subpage[];
}

export interface PagePreviewShort {
  guid: string;
  name: string;
  subpages: Subpage[];
  components: Component[];
}

export interface PageThumbnailTransformations {
  image_650x400_fit: {
    url: string;
  };
  image_433x266_fit: {
    url: string;
  };
  image_325x200_fit: {
    url: string;
  };
}

// This will be moved to conditions feature folder
export interface Condition {
  id: number; // 123456,
  index_weight: number; // 1,
  name: string; // "Name of the condition",
  filters: {
    operator: '$eq' | string; // "$eq,",
    conditions: {
      target: string; // "active",
      value: boolean; // true
    };
  };
  active: boolean;
}

export interface Host {
  _id: string; // "455551d661112",
  url: string; // "admit.spark451.com",
}

export type CreateHostBody = Pick<Host, 'url'>;

export interface Page451EditorComponentListItem {
  component: Component;
  image: string;
}

export interface Page451EditorComponentsGroup {
  label: string;
  type: string;
  feature_flag?: string;
  items: Page451EditorComponentListItem[];
}

export interface Component {
  _id: string; // 123456,
  index_weight: number; // 1,
  anchor: string | null; // "#goto",
  type: string; // TODO: list out all types  "button",
  default_version: 'default' | string; // "default",
  structure: ComponentStructure[];
  active: boolean;
}

export interface ComponentStructure {
  _id: string; // "59baade708032",
  version: 'default' | string; // "default",
  elements?: { [key: string]: boolean };
  [key: string]: any;
}

export interface PageLayoutColors {
  primary: string; // "#FFFFFF",
  secondary: string; // "rgb(10,10,10)",
  link: string; // "red"
}

export interface PageLayoutGeneral {
  colors?: PageLayoutColors;
  school_favicon_ico?: ApiFile;
  school_favicon_png?: ApiFile;
}

export type FaviconProps = 'school_favicon_png' | 'school_favicon_ico';

export interface PageLayout {
  preview_key: string; // "7D1A572022DD079A08D63D98C69D9012",
  preview_image: ApiFile; // "https://s3.amazonaws.com/spark/public/files/w9wnODgel9mGYcUwP2sK/preview.png",
  general?: PageLayoutGeneral;
}

export interface PageLocker {
  active: boolean;
  modal?: PageLockerModal;
}

export interface PageLockerModal {
  active: boolean;
  title: string; // "Locker Modal Title",
  description: string; // "Locker Modal Description",
  field_label: string | null;
  additional_info: string | null; //
  submit_label: string; // "Submit",
  cancel_label: string; // "Cancel"
}

export interface PageMessenger {
  active: boolean;
  appearance?: {
    launcher_icon_color: 'primary' | 'secondary' | 'custom';
  };
}

export interface PageBoltDiscovery {
  active: boolean;
}

export interface PageConfiguration {
  title: string; // "This is a awesome page",
  description: string; // "This is a landing page",
  analytics?: {
    google_tag_id: string; // "GTM-XXXX"
  };
  primary_domain?: PrimaryDomain;
  website_url: string | null;
  messenger?: PageMessenger;
  bolt_discovery?: PageBoltDiscovery;
  locker?: PageLocker;
  social_media_links?: SocialMediaApi.Links;
  social_media_feeds: SocialMediaApi.FeedProvider[];
  hosts: Host[];
  path?: string;
}

export interface Page {
  guid: string;
  name: string;
  status: string; // "published",
  type: 'page' | string; // 'page'
  has_unpublished_updates: boolean;
  last_publication_at: string; // 2017-04-26T13:39:11.394Z",
  layout: PageLayout;
  configuration: PageConfiguration;
  conditions: Condition[];
  components: Component[];
  created_at: string;
  updated_at: string;
  hosts: Host[];
  client: {
    cookies: SettingsApi.CookiesSetting;
    privacy: SettingsApi.PrivacySetting;
  };
  subpages: Subpage[];
}

export interface CustomComponent {
  author_id: string;
  component: string;
  created_at: string;
  guid: string;
  name: string;
  updated_at: string;
  _subdom: string;
}

export interface ExtendedCustomComponent
  extends Omit<CustomComponent, 'component'> {
  component: {
    active: boolean;
    anchor: string | null;
    id: string;
    default_version: string;
    index_weight: number;
    structure: ComponentStructure[];
  };
}

export interface SubpageConfiguration {
  description: string;
  path: string;
  title: string;
}
export interface Subpage {
  guid: string;
  name: string;
  type: 'subpage' | string;
  layout: {
    preview_image: ApiFile<PageThumbnailTransformations>; // "https://s3.amazonaws.com/spark/public/files/w9wnODgel9mGYcUwP2sK/preview.png",
  };
  configuration: SubpageConfiguration;
  components: Component[];
  created_at: string;
  updated_at: string;
  parent: string;
}

export interface SubpageDto {
  name: string;
  configuration: SubpageConfiguration;
  components: Component[];
}

export interface SubpageShort {
  guid: string;
  name: string;
  layout?: {
    preview_image: ApiFile<PageThumbnailTransformations>; // "https://s3.amazonaws.com/spark/public/files/w9wnODgel9mGYcUwP2sK/preview.png",
  };
  components?: Component[];
  configuration?: SubpageConfiguration;
}

export const isSubpage = /.*\.sites_subpages\.\d+/;

export const isSubpageRoute = (route: string): boolean => isSubpage.test(route);
